import React from "react";
import Layout from "../components/layout";
import Section from "../components/section";

const TermsOfServicePage = () => {
    return (
        <Layout>
            <div className="mt-10">
                <h1 className="text-4xl text-center font-bold">DFS/Coeus - Terms of service</h1>
            </div>
            <div className="container prose mt-4 max-w-7xl mx-auto px-8 xl:px-0 mb-[72px]">
                <h3>EFFECTIVE: November 18, 2022</h3>
                <h4>
                    <span className="underline">IMPORTANT, READ CAREFULLY</span> : YOUR USE OF AND ACCESS TO THE WEBSITE
                    AND PRODUCTS AND SERVICES AND ASSOCIATED SOFTWARE (COLLECTIVELY,
                    THE “SERVICES”) OF DATA FUSION SPECIALISTS, LLC (hereinafter referred to
                    as “DFS”) AND ITS SOLUTION (“COEUS”) IS CONDITIONED UPON YOUR
                    COMPLIANCE WITH AND ACCEPTANCE OF THESE TERMS, WHICH INCLUDE
                    YOUR AGREEMENT TO ARBITRATE CLAIMS. PLEASE REVIEW THOROUGHLY
                    BEFORE ACCEPTING.
                </h4>
                <h4>
                    BY CLICKING/CHECKING THE “I AGREE” BUTTON/BOX, ACCESSING THE
                    DFS/COEUS WEBSITE OR BY UTILIZING THE DFS/COEUS SERVICES YOU AGREE
                    TO BE BOUND BY THESE TERMS OF SERVICE AND ALL EXHIBITS, ORDER
                    FORMS, AND INCORPORATED POLICIES (THE “AGREEMENT” OR “TOS”). THE
                    DFS/COEUS SERVICES ARE NOT AVAILABLE TO PERSONS WHO ARE NOT
                    LEGALLY ELIGIBLE TO BE BOUND BY THESE TERMS OF SERVICE.
                </h4>
                <p>
                    DFS/Coeus will provide the Services, and you may access and use the Services, in
                    accordance with this Agreement. DFS/Coeus may provide any of the Services hereunder
                    through any of its Affiliates. If You order Services through an on-line registration page or
                    an order form (each an “Order Form”), the Order Form may contain additional terms
                    and conditions and information regarding the Services you are ordering. Unless
                    otherwise expressly set forth in any such additional terms and conditions applicable to
                    the specific Service which You choose to use, those additional terms are hereby
                    incorporated into this Agreement in relation to Your use of that Service.
                </p>
                <p>
                    <span className="font-bold underline">System Requirements</span>. Use of the Services requires one or more compatible devices,
                    Internet access (fees may apply), and certain software (fees may apply), and may
                    require obtaining updates or upgrades from time to time. Because use of the Services
                    involves hardware, software, and Internet access, Your ability to access and use the
                    Services may be affected by the performance of these factors. High speed Internet
                    access is recommended. You acknowledge and agree that such system requirements,
                    which may be changed from time to time, are Your responsibility.
                </p>
                <ol>
                    <li>
                        <ul className="list-none">
                            <li>
                                <span className="font-bold">DEFINITIONS.</span> The following definitions will apply in this Agreement, and any
                                reference to the singular includes a reference to the plural and vice versa.
                            </li>
                            <li>
                                <span className="font-bold">“Affiliate”</span> means, with respect to a Party, any entity that directly or indirectly
                                controls, is controlled by or is under common control with that Party.
                            </li>
                            <li>
                                <span className="font-bold">“End User”</span> means a user or Participant who uses the Services.
                            </li>
                            <li>
                                <span className="font-bold">“Initial Subscription Term”</span> means the initial subscription term for a Service as
                                specified in an Order Form.
                            </li>
                            <li>
                                <span className="font-bold">“Laws”</span> means all U.S. or non-U.S. national, regional, state, provincial or local
                                laws, statutes, rules, regulations, ordinances, administrative rulings, judgments,
                                decrees, orders, directives, policies, or treaties applicable to DFS/Coeus’s
                                provision and Customer’s use of the Services.
                            </li>
                            <li>
                                <span className="font-bold">“Service Effective Date”</span> means the date an Initial Subscription Term begins as
                                specified in an Order Form.
                            </li>
                            <li>
                                <span className="font-bold">“Renewal Term”</span> means the renewal subscription term for a Service
                                commencing after the Initial Subscription Term or another Renewal Term as
                                specified in an Order Form.
                            </li>
                            <li>
                                <span className="font-bold">“Taxes and Fees”</span> and “Taxes or Fees” means all applicable sales, use,
                                environmental or regulatory taxes, charges, surcharges or assessments levied on
                                the provision of Services to Customer (exclusive of any income tax imposed on
                                DFS/Coeus).
                            </li>
                            <li>
                                <span className="font-bold">“Your Data”</span> means information provided to DFS/Coeus so that DFS/Coeus can
                                fulfill the terms of the Agreement and provide access to the Services (e.g.,
                                company name, billing address, taxpayer ID number, VAT registration number,
                                contact name and information). You are solely responsible for the accuracy of
                                Your Data, and DFS/Coeus has no liability whatsoever for errors and omissions in
                                Your Data.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="font-bold">SERVICES.</span> DFS/Coeus will provide the Services as described on the Order Form,
                        and standard updates to the Services that are made generally available by
                        DFS/Coeus during the term. DFS/Coeus may, in its sole discretion, discontinue
                        the Services or modify the features of the Services from time to time without
                        prior notice.
                    </li>
                    <li>
                        <span className="font-bold">USE OF SERVICES AND YOUR RESPONSIBILITIES.</span> You may only use the
                        Services pursuant to the terms of this Agreement. You are solely responsible for
                        Your and Your End Users’ use of the Services and shall abide by, and ensure
                        compliance with, all Laws in connection with Your and each End User’s use of the
                        Services, including but not limited to Laws related to recording, intellectual
                        property, privacy and export control. Use of the Services is void where
                        prohibited.
                    </li>
                    <ol className="list-[upper-alpha]">
                        <li>
                            <span className="font-bold">Registration Information.</span> You may be required to provide information
                            about Yourself in order to register for and/or use certain Services. You
                            agree that any such information shall be accurate. You may also be asked
                            to choose a username and password. You are entirely responsible for
                            maintaining the security of Your username and password and agree not
                            to disclose such to any third party.
                        </li>
                        <li>
                            <span className="font-bold">Your Content.</span> You agree that You are solely responsible for the content
                            (“Content”) sent or transmitted by You or displayed or uploaded by You in
                            using the Services and for compliance with all Laws pertaining to the
                            Content, including, but not limited to, Laws requiring You to obtain the
                            consent of a third party to use the Content and to provide appropriate
                            notices of third party rights. You represent and warrant that You have the
                            right to upload the Content to DFS/Coeus and that such use does not
                            violate or infringe on any rights of any third party. Under no circumstances
                            will DFS/Coeus be liable in any way for any (a) Content that is transmitted
                            or viewed while using the Services, (b) errors or omissions in the Content,
                            or (c) any loss or damage of any kind incurred as a result of the use of,
                            access to, or denial of access to Content. Although DFS/Coeus is not
                            responsible for any Content, DFS/Coeus may delete any Content, at any
                            time without notice to You, if DFS/Coeus becomes aware that it violates
                            any provision of this Agreement, or any law. You retain copyright and any
                            other rights You already hold in Content which You submit, post or display
                            on or through, the Services.
                        </li>
                        <li>
                            <span className="font-bold">Prohibited Use.</span> You agree that You will not, and will not permit any End
                            User to: (i) modify, customize, disassemble, decompile, prepare derivative
                            works of, create improvements, derive innovations from, reverse engineer
                            or attempt to gain access to any underlying technology of the Services
                            (e.g., any source code, process, data set or database, management tool,
                            development tool, server or hosting site, etc); (ii) knowingly or negligently
                            use the Services in a way that abuses, interferes with, or disrupts
                            DFS/Coeus’s networks, Your accounts, or the Services; (iii) engage in
                            activity that is illegal, fraudulent, false, or misleading, (iv) transmit
                            through the Services any material that may infringe the intellectual
                            property or other rights of third parties; (v) build or benchmark a
                            competitive product or service, or copy any features, functions or graphics
                            of the Services; or (vi) use the Services to communicate any message or
                            material that is harassing, libelous, threatening, obscene, indecent, would
                            violate the intellectual property rights of any party or is otherwise
                            unlawful, that would give rise to civil liability, or that constitutes or
                            encourages conduct that could constitute a criminal offense, under any
                            applicable law or regulation; (vii) upload or transmit any software, Content
                            or code that does or is intended to harm, disable, destroy or adversely
                            affect performance of the Services in any way or which does or is intended
                            to harm or extract information or data from other hardware, software or
                            networks of DFS/Coeus or other users of Services; (viii) engage in any
                            activity or use the Services or DFS/Coeus account in any manner that
                            could damage, disable, overburden, impair or otherwise interfere with or
                            disrupt the Services, or any servers or networks connected to the Services
                            or DFS/Coeus’s security systems. (ix) use the Services in violation of any
                            DFS/Coeus policy or in a manner that violates applicable law, including but
                            not limited to anti-spam, export control, privacy, and anti-terrorism laws
                            and regulations and laws requiring the consent of subjects of audio and
                            video recordings, and You agree that You are solely responsible for
                            compliance with all such laws and regulations.
                        </li>
                        <li>
                            <span className="font-bold">Limitations on Use.</span> You may not reproduce, resell, or distribute the
                            Services or any reports or data generated by the Services for any purpose
                            unless You have been specifically permitted to do so under a separate
                            agreement with DFS/Coeus. You may not offer or enable any third parties
                            to use the Services purchased by You, display on any website or otherwise
                            publish the Services or any Content obtained from a Service (other than
                            Content created by You) or otherwise generate income from the Services
                            or use the Services for the development, production or marketing of a
                            service or product substantially similar to the Services.
                        </li>
                    </ol>
                    <li>
                        <span className="font-bold">RESPONSIBILITY FOR END USERS.</span> You are responsible for the activities of all
                        End Users who access or use the Services through Your account and You agree to
                        ensure that any such End User will comply with the terms of this Agreement and
                        any DFS/Coeus policies. DFS/Coeus assumes no responsibility or liability for
                        violations. If You become aware of any violation of this Agreement in connection
                        with use of the Services by any person, please contact DFS/Coeus at
                        trust@DFS/Coeus.chat DFS/Coeus may investigate any complaints and violations
                        that come to its attention and may take any (or no) action that it believes is
                        appropriate, including, but not limited to issuing warnings, removing the content
                        or terminating accounts and/or User profiles. Under no circumstances will
                        DFS/Coeus be liable in any way for any data or other content viewed while using
                        the Services, including, but not limited to, any errors or omissions in any such
                        data or content, or any loss or damage of any kind incurred as a result of the use
                        of, access to, or denial of access to any data or content
                    </li>
                    <li>
                        <span className="font-bold">DFS/COEUS OBLIGATIONS FOR CONTENT.</span> DFS/Coeus will maintain
                        reasonable physical and technical safeguards to prevent unauthorized disclosure
                        of or access to Content, in accordance with industry standards. DFS/Coeus will
                        notify You if it becomes aware of unauthorized access to Content. DFS/Coeus will
                        not access, view or process Content except (a) as provided for in this Agreement
                        and in DFS/Coeus’s Privacy Statement; (b) as authorized or instructed by You, (c)
                        as required to perform its obligations under this Agreement; or (d) as required by
                        Law. DFS/Coeus has no other obligations with respect to Content.
                    </li>
                    <li>
                        <span className="font-bold">ELIGIBILITY.</span> You affirm that You are at least 18 years of age and are otherwise
                        fully able and competent to enter into the terms, conditions, obligations,
                        affirmations, representations, and warranties set forth in this Agreement, and to
                        abide by and comply with this Agreement. Your access may be terminated
                        without warning if we reasonably believe that You are under the age of 18 or are otherwise
                        ineligible.
                    </li>
                    <li>
                        <span className="font-bold">INTENDED USE; RESTRICTION ON USE BY CHILDREN.</span> The Services are
                        intended for business use. You may choose to use the Services for other
                        purposes, subject to the terms and limitations of this Agreement. DFS/Coeus is
                        not for use by individuals under the age of 18.
                    </li>
                    <li>
                        <span className="font-bold">CHARGES AND CANCELLATION.</span> You agree that DFS/Coeus may charge to Your
                        credit card or other payment mechanism selected by You and approved by
                        DFS/Coeus (“Your Account”) all amounts due and owing for the Services. All
                        payments made by You to us under this Agreement will be made free and clear
                        of any deduction or withholding, as may be required by law. If any such
                        deduction or withholding (including but not limited to domestic or cross-border
                        withholding taxes) is required on any payment, You will pay such additional
                        amounts as are necessary so that the net amount received by us is equal to the
                        amount then due and payable under this Agreement. We will provide You with
                        such tax forms as are reasonably requested in order to reduce or eliminate the
                        amount of any withholding or deduction for taxes in respect of payments made
                        under this Agreement. DFS/Coeus may change prices at any time, including
                        changing from a free service to a paid service and charging for Services that
                        were previously offered free of charge; provided, however, that DFS/Coeus will
                        provide You with prior notice and an opportunity to terminate Your Account if
                        DFS/Coeus changes the price of a Service to which You are subscribed and will
                        not charge You for a previously free Service unless You have been notified of the
                        applicable fees and agreed to pay such fees. You agree that in the event
                        DFS/Coeus is unable to collect the fees owed to DFS/Coeus for the Services
                        through Your Account, DFS/Coeus may take any other steps it deems necessary
                        to collect such fees from You and that You will be responsible for all costs and
                        expenses incurred by DFS/Coeus in connection with such collection activity,
                        including collection fees, court costs and attorneys’ fees. You further agree that
                        DFS/Coeus may collect interest at the lesser of 1.5% per month or the highest
                        amount permitted by law on any amounts not paid when due. You may cancel
                        Your subscription at any time. If You cancel, You will not be billed for any
                        additional terms of service, and service will continue until the end of the current
                        Subscription Term. If You cancel, You will not receive a refund for any service
                        already paid for.
                    </li>
                    <li>
                        <span className="font-bold">TAXES.</span> Unless stated otherwise, all prices and fees shown by DFS/Coeus are
                        exclusive of Taxes and regulatory fees, service, service fees, set up fees,
                        subscription fees, or any other fee or charge associated with Your Account.
                        Where applicable, Taxes and regulatory fees will be charged on the invoices
                        issued by DFS/Coeus in accordance with local laws and regulations. DFS/Coeus,
                        in its sole discretion, will calculate the amount of Taxes due. The taxes and
                        regulatory fees charged can be changed without notice.
                    </li>
                    <ol className="list-[upper-alpha]">
                        <li>
                            <span className="font-bold">Tax exemptions.</span> If You are exempt from any Tax or Fee, You will provide
                            DFS/Coeus with all appropriate tax exemption certificates, and/or other
                            documentation satisfactory to the applicable taxing authorities to
                            substantiate such exemption status. DFS/Coeus reserves the right to
                            review and validate tax exemption documentation. In the event that the
                            tax exemption documentation is not valid, DFS/Coeus reserves the right to
                            charge applicable taxes to You.
                        </li>
                        <li>
                            <span className="font-bold">Payment of Taxes and Fees.</span> You will pay to DFS/Coeus any applicable
                            Taxes and Fees. You are solely responsible for paying any and all Taxes
                            and Fees owing as a result of DFS/Coeus’s provision of the Services to You.
                            If You are required to pay any Taxes and Fees, You shall pay such
                            amounts with no reduction or offset in amounts payable to DFS/Coeus
                            hereunder and You will pay and bear such additional amount, as shall be
                            necessary such that DFS/Coeus receives the full amount of payment
                            required as if no such reduction or offset were required.
                        </li>
                        <li>
                            <span className="font-bold">Tax determination.</span> Tax determination is principally based on the
                            location where the Customer has established its business based on the
                            Customer Data, or for individuals where that individual permanently
                            resides. This will be defined by DFS/Coeus as Your ‘Sold To’ address.
                            DFS/Coeus reserves the right to cross reference this location against other
                            available evidence to validate whether Your location is accurate. In the
                            event that Your location is inaccurate, DFS/Coeus reserves the right to
                            charge You any outstanding Taxes and Fees.
                        </li>
                        <li>
                            <span className="font-bold">Use and enjoyment.</span> If You purchase DFS/Coeus Services, and those
                            Services are used and enjoyed by a subsidiary of You in a country that is
                            different than Your location as determined by Section 9(c) of this TOS, You
                            confirm that where required You will treat this as a supply to Your
                            subsidiary. In the event You purchase Services and those Services are
                            used and enjoyed by a branch or individual in a country that is different to
                            Your location as determined by Section 9 (c) of this TOS, You acknowledge
                            that You will inform DFS/Coeus of the Services that have been allocated
                            and You acknowledge that DFS/Coeus reserves the right to charge Taxes
                            and Fees based on the use and enjoyment of those Services.
                        </li>
                    </ol>
                    <li>
                        <span className="font-bold">TERMINATION.</span> The DFS/Coeus website contains information on how to
                        terminate Your Account. If you have purchased a Service for a specific term, such
                        termination will be effective on the last day of the then-current term. Your Order
                        Form may provide that a Renewal Term will begin automatically unless either
                        party provides written notice of termination at least thirty (30) days prior to the
                        commencement of the next Renewal Term. If You fail to comply with any
                        provision of this Agreement, DFS/Coeus may terminate this Agreement
                        immediately and retain any fees previously paid by You. Sections 1 and 3
                        through 21, inclusive, shall survive any termination of this Agreement. Upon any
                        termination of this Agreement, You must cease any further use of the Services. If
                        at any time You are not happy with the Services, Your sole remedy is to cease
                        using the Services and follow this termination process. DFS may, in its sole discretion, terminate or suspend your access to all or part of the Services, at any time without notice, for any reason, including, without limitation, breach or assignment of these Terms of Service.
                    </li>
                    <li>
                        <span className="font-bold">PROPRIETARY RIGHTS.</span> DFS/Coeus and/or its suppliers, as applicable, retain
                        ownership of all proprietary rights in the Services and in all trade names,
                        trademarks, service marks, logos, and domain names (“DFS/Coeus Marks”)
                        associated or displayed with the Services. You may not frame or utilize framing
                        techniques to enclose any DFS/Coeus Marks, or other proprietary information
                        (including images, text, page layout, or form) of DFS/Coeus without express
                        written consent. You may not use any meta tags or any other “hidden text”
                        utilizing DFS/Coeus Marks without DFS/Coeus’s express written consent.
                    </li>
                    <li>
                        <span className="font-bold">CONFIDENTIALITY.</span> Each party agrees to regard and preserve as confidential all
                        non-public information provided by the other party relating to the business,
                        systems, operations, source code, Services, strategic plans, clients, pricing
                        (including, but not limited to, the pricing terms herein), methods, processes,
                        financial data, programs, and/or products of the other party in any form, that are
                        designated as “confidential,” or a reasonable person knows or reasonably should
                        understand to be confidential (herein “Confidential Information”). Each party
                        agrees to limit its disclosure of the other party’s Confidential Information to as
                        few persons as possible and only to those persons with a need to know that are
                        its or its Affiliates’ personnel and subject to an obligation to keep such
                        information confidential. Except as needed to fulfill their respective obligations
                        under the Agreement or as expressly permitted herein, the receiving party shall
                        not: (i) disclose the disclosing party’s Confidential Information to any person,
                        firm, or enterprise without the disclosing party’s prior written consent, or (ii) use
                        the disclosing party’s Confidential Information for its own benefit, or the benefit
                        of a third party.
                    </li>
                    <ol className="list-[upper-alpha]">
                        <li>
                            <span className="font-bold">Exclusions.</span> “Confidential Information” shall not include Content or
                            information that (a) is already rightfully known to a party at the time it is
                            obtained from the other party, free from any obligation to keep such
                            information confidential; (b) is or becomes publicly known or available
                            through no wrongful act of a party; (c) is rightfully received from a third
                            party without restriction and without breach of this TOS; or (d) is
                            developed by a party without the use of any proprietary, non-public
                            information provided by the other party under the Agreement.
                        </li>
                        <li>
                            <span className="font-bold">Exception.</span> Either party may disclose Confidential Information where
                            required by law, regulation, or court order, provided that the party subject
                            to such law, regulation or court order shall, where permitted, notify the
                            other party of any such use or requirement prior to disclosure in order to
                            afford such other party an opportunity to seek a protective order to
                            prevent or limit disclosure of the information to third parties.
                        </li>
                        <li>
                            <span className="font-bold">Confidentiality Period and Obligations.</span> The confidentiality obligations
                            set forth in this section of the TOS shall remain in effect for a period of five
                            (5) years from the disclosure of the information. Both parties agree (a) to
                            take reasonable steps to protect the other party’s Confidential
                            Information, and these steps must be at least as protective as those the
                            receiving party takes to protect its own Confidential Information, and no
                            less than a reasonable standard of care; (b) to notify the disclosing party
                            promptly upon discovery of any unauthorized use or disclosure of
                            Confidential Information; and (c) in the event of any unauthorized
                            disclosure by a receiving party, to cooperate with the disclosing party to
                            help regain control of the Confidential Information and prevent further
                            unauthorized use or disclosure of it.
                        </li>
                    </ol>
                    <li>
                        <span className="font-bold">COPYRIGHT.</span> You may not post, modify, distribute, or reproduce in any way
                        copyrighted material, trademarks, rights of publicity or other proprietary rights
                        without obtaining the prior written consent of the owner of such proprietary
                        rights. DFS/Coeus may deny access to the Services to any User who is alleged to
                        infringe another party’s copyright. Without limiting the foregoing, if You believe
                        that Your copyright has been infringed, please notify DFS/Coeus as specified at&nbsp;
                        <a href="mailto:copyright@coeus.chat">copyright@coeus.chat.</a>
                    </li>
                    <li>
                        <span className="font-bold">EXPORT RESTRICTIONS.</span> You acknowledge that the Services, or a portion
                        thereof, are subject to the Export Administration Regulations, 15 C.F.R. Parts
                        730-774, of the United States and may be subject to other applicable country
                        export control and trade sanctions laws (“Export Control and Sanctions Laws”).
                        You and Your End Users may not access, use, export, re-export, divert, transfer
                        or disclose any portion of the Services or any related technical information or
                        materials, directly or indirectly, in violation of Export Control and Sanctions Laws.
                        You represent and warrant that: (i) You and Your End Users (a) are not citizens of,
                        or located within, a country or territory that is subject to U.S. trade sanctions or
                        other significant trade restrictions (including without limitation Cuba, Iran, North
                        Korea, Syria, and the Crimea, Donetsk and Luhansk regions of Ukraine) and that
                        You and Your End Users will not access or use the Services, or export, re-export,
                        divert, or transfer the Services, in or to such countries or territories; (b) are not
                        persons, or owned 50% or more, individually or in the aggregate by persons,
                        identified on the U.S. Department of the Treasury’s Specially Designated
                        Nationals and Blocked Persons List or Foreign Sanctions Evaders Lists; and (c) are
                        not persons on the U.S. Department of Commerce’s Denied Persons List, Entity
                        List, or Unverified List, or U.S. Department of State proliferation-related lists; (ii)
                        You and Your End Users located in China, Russia, or Venezuela are not Military
                        End Users and will not put DFS/Coeus’s Services to a Military End Use, as defined
                        in 15 C.F.R. 744.21; (iii) no Content created or submitted by You or Your End
                        Users is subject to any restriction on disclosure, transfer, download, export or re-
                        export under the Export Control and Sanctions Laws; and (iv) You and Your End
                        Users will not take any action that would constitute a violation of, or be penalized
                        under, U.S. antiboycott laws administered by the U.S. Department of Commerce
                        or the U.S. Department of the Treasury. You are solely responsible for complying
                        with the Export Control and Sanctions Laws and monitoring them for any
                        modifications.
                    </li>
                    <li>
                        <span className="font-bold">NO HIGH RISK USE.</span> The Services are not designed or licensed for use in
                        hazardous environments requiring fail-safe controls, including without limitation
                        operation of nuclear facilities, aircraft navigation/communication systems, air
                        traffic control, and life support or weapons systems. The Services shall not be
                        used for or in any HIGH RISK environment
                    </li>
                    <li>
                        <span className="font-bold">INJUNCTIVE RELIEF.</span> You acknowledge that any use of the Services contrary to
                        this Agreement, or any transfer, sublicensing, copying or disclosure of technical
                        information or materials related to the Services, may cause irreparable injury to
                        DFS/Coeus, its Affiliates, suppliers and any other party authorized by DFS/Coeus
                        to resell, distribute, or promote the Services (“Resellers”), and under such
                        circumstances DFS/Coeus, its Affiliates, suppliers and Resellers will be entitled to
                        equitable relief, without posting bond or other security, including, but not limited
                        to, preliminary and permanent injunctive relief.
                    </li>
                    <li>
                        <span className="font-bold">NO WARRANTIES.</span> YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE
                        PROVIDED “AS IS” AND DFS/COEUS, ITS AFFILIATES, SUPPLIERS AND RESELLERS
                        EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                        INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. DFS/COEUS, ITS
                        AFFILIATES, SUPPLIERS AND RESELLERS MAKE NO WARRANTY OR
                        REPRESENTATION REGARDING THE RESULTS THAT MAY BE OBTAINED FROM THE
                        USE OF THE SERVICES, REGARDING THE ACCURACY OR RELIABILITY OF ANY
                        INFORMATION OBTAINED THROUGH THE SERVICES OR THAT THE SERVICES WILL
                        MEET ANY USER’S REQUIREMENTS, OR BE UNINTERRUPTED, TIMELY, SECURE OR
                        ERROR FREE. USE OF THE SERVICES IS AT YOUR SOLE RISK. ANY MATERIAL
                        AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
                        THE SERVICES IS AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY
                        RESPONSIBLE FOR ANY DAMAGE TO YOU RESULTING FROM THE USE OF THE
                        SERVICES. THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF THE
                        SERVICES REMAINS WITH YOU. DFS/COEUS DOES NOT ASSUME ANY
                        RESPONSIBILITY FOR RETENTION OF ANY USER INFORMATION OR
                        COMMUNICATIONS BETWEEN USERS. DFS/COEUS CANNOT GUARANTEE AND
                        DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE SERVICES. USE IS
                        AT YOUR OWN RISK.
                    </li>
                    <li>
                        <span className="font-bold">INDEMNIFICATION.</span> You agree to indemnify, defend and hold harmless
                        DFS/Coeus, its affiliates, officers, directors, employees, consultants, agents,
                        suppliers and Resellers from any and all third party claims, liability, damages
                        and/or costs (including, but not limited to, attorneys’ fees) arising from Your use
                        of the Services, Your violation of this Agreement or the infringement or violation
                        by You or any other user of Your account, of any intellectual property or other
                        right of any person or entity or applicable law.
                    </li>
                    <li>
                        <span className="font-bold">LIMITATION OF LIABILITY.</span> TO THE MAXIMUM EXTENT PERMITTED BY
                        APPLICABLE LAW, IN NO EVENT WILL DFS/COEUS OR ITS AFFILIATES'S, SUPPLIERS'S
                        OR RESELLERS'S BE LIABLE FOR ANY SPECIAL,INCIDENTAL, INDIRECT, EXEMPLARY,
                        PUNITIVE OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT
                        LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS
                        INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY
                        LOSS OR DAMAGE) ARISING OUT OF THE USE OF OR INABILITY TO USE THE
                        SERVICES OR THE PROVISION OF OR FAILURE TO PROVIDE TECHNICAL OR OTHER
                        SUPPORT SERVICES, WHETHER ARISING IN TORT (INCLUDING NEGLIGENCE)
                        CONTRACT OR ANY OTHER LEGAL THEORY, EVEN IF DFS/COEUS, ITS AFFILIATES,
                        SUPPLIERS OR RESELLERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES. IN ANY CASE, DFS/COEUS’S, ITS AFFILIATES’, SUPPLIERS’ AND
                        RESELLERS’ MAXIMUM CUMULATIVE LIABILITY AND YOUR EXCLUSIVE REMEDY
                        FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL BE
                        LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU FOR THE SERVICES (IF ANY) IN
                        THE TWELVE (12) MONTHS PRECEDING THE EVENT OR CIRCUMSTANCES GIVING
                        RISE TO SUCH CLAIMS.
                    </li>
                    <li>
                        <span className="font-bold">DISPUTE RESOLUTION; WAIVER OF CLASS ACTION.</span>  You agree to
                        resolve disputes only on an individual basis, pursuant to the
                        provisions of Exhibit A attached hereto. <span className="font-bold">The parties expressly waive any right to bring any
                            action, lawsuit, or proceeding as a class or collective action, private
                            attorney general action, or any other proceeding in which any party
                            acts or proposes to act in a representative capacity.</span>
                    </li>
                    <li>
                        <span className="font-bold">MISCELLANEOUS</span>
                    </li>
                    <ol className="list-[upper-alpha]">
                        <li>
                            <span className="font-bold">Choice of Law and Forum.</span> This Agreement shall be governed by and
                            construed under the laws of the State of Georgia, U.S.A. Except as
                            provided in Exhibit A, the Parties consent to the exclusive jurisdiction and
                            venue of the Superior Court of Floyd County, Georgia. U.S.A.
                        </li>
                        <li>
                            <span className="font-bold">Waiver and Severability.</span> Failure by either Party to exercise any of its
                            rights under, or to enforce any provision of, this Agreement will not be
                            deemed a waiver or forfeiture of such rights or ability to enforce such
                            provision. If any provision of this Agreement is held by a court of
                            competent jurisdiction to be illegal, invalid or unenforceable, that
                            provision will be amended to achieve as nearly as possible the same
                            economic effect of the original provision and the remainder of this
                            Agreement will remain in full force and effect.
                        </li>
                        <li>
                            <span className="font-bold">General Provisions.</span> This Agreement embodies the entire understanding
                            and agreement between the Parties respecting the subject matter of this
                            Agreement and supersedes any and all prior understandings and
                            agreements between the Parties respecting such subject matter, except
                            that if You or Your company have executed a separate written agreement
                            or You have signed an order form referencing a separate agreement
                            governing Your use of the Services, then such agreement shall control to
                            the extent that any provision of this Agreement conflicts with the terms of
                            such agreement. DFS/Coeus may elect to change or supplement the terms
                            of this Agreement from time to time at its sole discretion. DFS/Coeus will
                            exercise commercially reasonable business efforts to provide notice to You
                            of any material changes to this Agreement. Within ten (10) business days
                            of posting changes to this Agreement (or ten (10) business days from the
                            date of notice, if such is provided), they will be binding on You. If You do
                            not agree with the changes, You should discontinue using the Services. If
                            You continue using the Services after such ten-business-day period, You
                            will be deemed to have accepted the changes to the terms of this
                            Agreement. In order to participate in certain Services, You may be notified
                            that You are required to download software and/or agree to additional
                            terms and conditions. Unless expressly set forth in such additional terms
                            and conditions, those additional terms are hereby incorporated into this
                            Agreement. This Agreement has been prepared in the English Language
                            and such version shall be controlling in all respects and any non-English
                            version of this Agreement is solely for accommodation purposes.
                        </li>
                    </ol>
                </ol>
                <h1 className="text-center">Exhibit A</h1>
                <h1 className="text-center">Binding Arbitration</h1>
                <p>
                    This Exhibit A to the “Terms of Service” describes the further provisions which apply to
                    the Binding Arbitration and Class Action Waiver.
                </p>
                <ol className="list-[upper-alpha]">
                    <li>
                        <span className="font-bold">Disputes.</span> A dispute (herein, “Dispute”) is any controversy between You and DFS/Coeus concerning
                        the Services, any software related to the Services, the price of the Services, Your account,
                        DFS/Coeus’s advertising, marketing, or communications, Your purchase
                        transaction or billing, or any term of this Agreement, under any legal theory
                        including contract, warranty, tort, statute, or regulation,
                        except disputes relating to the enforcement or validity of Your or DFS/Coeus’s
                        intellectual property rights. As part of the best efforts process to resolve
                        disputes, and prior to initiating arbitration proceedings, each party agrees to
                        provide notice of the dispute to the other party, including a description of the
                        dispute, what efforts have been made to resolve it, and what the disputing party
                        is requesting as resolution, to&nbsp;
                        <a href="mailto:legal@coeus.chat">legal@coeus.chat.</a>
                    </li>
                    <li>
                        <span className="font-bold">Governing Law.</span> This Agreement shall be governed by the laws of Georgia, including any conflict of laws issues.
                    </li>
                    <li>
                        <span className="font-bold">Dispute Resolution Procedure.</span> In the event any Dispute arises between the Parties, all such disputes shall be resolved by mediation/arbitration between the Parties.  If mediation is unsuccessful in resolving the Dispute, then the Parties agree to binding arbitration to fully and finally resolve any such disputes. Mediation shall be conducted as agreed upon between the Parties; failing some agreement, then the mediation shall be accomplished through the State of Georgia Mediation Program having jurisdiction over the Superior Court Judicial System of Floyd County, Georgia.  The Alternative Dispute Director shall choose or provide the Parties with three (3) choices of a mediator.  The parties shall choose a mediator from these three (3) choices.  If they cannot agree, then the Director shall choose from these three (3) choices, a mediator. Mediation shall be conducted as soon as possible, but no longer than thirty (30) days after mediation is invoked by either party, unless agreed upon between the Parties.  Costs of mediation shall be divided, and any mediation agreement shall be in writing, conclusively ending the Parties’ Dispute.
                    </li>
                    <li>
                        <span className="font-bold">Arbitration Procedure.</span> In the event mediation is unsuccessful, then either the mediator shall immediately become an arbitrator of the Dispute; or the Parties shall agree upon an arbitrator to settle completely any and all remaining issues as between them regarding any Dispute that arises pursuant to this Agreement. If they are unable to agree upon a neutral arbitrator, then the Alternative Dispute Director shall choose an arbitrator who has accomplished business arbitrations and/or alternative dispute resolutions involving pertinent issues which might arise pursuant to this Agreement. The costs of the arbitration shall be equally divided between the Parties and all portions of the arbitration proceedings shall be conducted under the Georgia Arbitration Code, as presently or hereafter amended.  The decision of the arbitrator shall be final, and, if necessary, published and filed with the Superior Court of Floyd County; pursuant to the then applicable laws of Georgia involving arbitrations. The Parties, for themselves, their successors and assigns, agree to be bound by all terms, conditions and provisions of any final arbitration award.  The decision of arbitration shall encompass any and all Dispute which might arise pursuant to this Agreement and any and all other matters related thereto.  It is the parties’ intention that all claims shall be governed by the mediation and arbitration provisions set forth herein.  Therefore, the Parties respectively waive any right to a jury trial of any action, claim or other proceeding which might arise pursuant to this Agreement.  These alternative dispute resolution provisions are a material inducement for each of the Parties to enter into this Agreement.
                    </li>
                </ol>
            </div>
        </Layout>
    );
};

export default TermsOfServicePage;
